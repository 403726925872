import { useParams, useSearchParams } from 'react-router-dom';
import { FaSistrix, FaCheck, FaPen, FaIdCardClip } from 'react-icons/fa6';

interface Funcionarios {
  id: string | undefined,
  foto: string | undefined,
  name: string | undefined,
  datainicialização: string | undefined,
  função: string | undefined
}

const funcionarios: Array<Funcionarios> = [JSON.parse((process.env.REACT_APP_FUNC1 as string)), JSON.parse((process.env.REACT_APP_FUNC2 as string)), JSON.parse((process.env.REACT_APP_FUNC3 as string)), JSON.parse((process.env.REACT_APP_FUNC4 as string)),  JSON.parse((process.env.REACT_APP_FUNC5 as string))]
export default function Funcionario() {
  const [query] = useSearchParams();
  const paramid = useParams().id;
  if (query.get('codeacess') === process.env.REACT_APP_ACESSCODE) {
    const funcionario = funcionarios.find((ob) => ob.id === `${paramid}`)
    if (funcionario) {
      return (
        <div className='flex flex-col justify-center h-[35rem]'>
          <img src={funcionario.foto} alt='foto do funcionario' className='self-center w-36 h-40 mb-5' />
          <text className='flex self-center text-3xl'><FaPen className='mr-2' />Nome: {funcionario.name};</text>
          <text className='flex self-center text-3xl'><FaIdCardClip className='mr-2' />Função: {funcionario.função};</text>
          <text className='self-center text-3xl'>Data de admissão: {funcionario.datainicialização}.</text>
          <div className='self-center flex text-3xl bg-green-600 rounded-md text-white'><FaCheck className='mr-3' /> Funcionário verificado!</div>
        </div>
      )
    }
    else {
      return (
        <div className='flex flex-col justify-center align-center w-screen h-screen'>
          <FaSistrix className='self-center text-5xl mb-5' />
          <h5 className='self-center w-100 text-2xl md:text-5xl'>Funcionário não encontrado!</h5>
          <h3 className='self-center text-sm md:w-[30rem]'>Obs: essa tela apareceu pois o funcionário em questão não foi encontrado; verfique se o mesmo foi desligado da empresa ou se o id de pesquisa está correto, cada funcionário ligado a empresa tem sua propria pagina definada *estaticamente* se a mesma não existir e o uso do cracha é continuo é provavel ser uma tentativa de fraúde, para mais informações entre em contato pelo <a href={'/contato'}><span className='underline text-blue-500' >suporte</span></a>.</h3>
        </div>
      )
    }
  }
  else {
    return (
      <div>
        <text>acesso negado</text>
      </div>
    )
  }
}
